.spinner1 {
  width: 60px;
  height: 60px;
  border: 5px solid #dddddd;
  border-top-color: #423d68;
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
