@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    font-family:'Poppins', sans-serif;
}
.container-box{
    padding-left: 100px;
    padding-right: 100px;
}
.shadow--bg{
    box-shadow: 0px 4px 20px #00000029;
}

option:hover{
    background-color: gray;

}
canvas{
    height: 270px;
    width: 550px;
}

input[type="checkbox"] {
    visibility: hidden;
  }
  input[type="checkbox"] + label:before {
    border: 1px solid #333;
    content: "\00a0";
    display: inline-block;
    font: 16px/1em sans-serif;
    height: 16px;
    margin: 0 15px 0 0;
    padding: 0;
    vertical-align: top;
    width: 16px;
  }
  input[type="checkbox"]:checked + label:before {
    background: white;
    color: #B72E9B;
    content: "\2713";
    text-align: center;
  }




